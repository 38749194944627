@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  background: radial-gradient(257.52% 138.78% at 100% 97.76%, rgba(255, 214, 7, 0.04) 0%, rgba(91, 192, 235, 0.05) 32.5%), radial-gradient(160.4% 98.68% at 4.37% 5.19%, rgba(255, 212, 7, 0.01) 4.56%, rgba(91, 192, 235, 0.02) 39%), #F5F7FB;
}

.App {
  max-width: 1800px;
  margin: 0 auto;
  min-height: 101vh;
}

.button_wrapper {
  display: grid;
  min-height: 101vh;
}

.auth_button {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  min-height: 50px;
  color: #F5F7FB;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
  font-family: Poppins;
  font-size: 24.83px;
    
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.546px;
  border-radius: 9px;
background: var(--dark, #293748);
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
padding: 25px 44px;
margin-top: 40px;
gap:26px;
}

.auth_button_wrapper {
  display: grid;
  justify-items: center;
  align-items: start;
  align-content: start;
  gap:10px;
}

.auth_button_title {
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  opacity: 0.8;
  justify-self: center;

  max-width: 800px;
}

.auth_button > img {
  width: 100%;
}


.init-title-1 {
color: var(--dark, #293748);
text-align: center;
font-family: Poppins;
font-size: 23.05px;
font-style: normal;
font-weight: 300;
line-height: 168%; /* 38.724px */
letter-spacing: 2.766px;
text-transform: capitalize;
max-width: 550px;
}

.init-title-2 {
  color: var(--dark, #293748);
text-align: center;
font-family: Poppins;
font-size: 36.05px;
font-style: normal;
font-weight: 500;
line-height: 168%; /* 60.564px */
max-width: 550px;
}
